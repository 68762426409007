const Config = {
  // domain: 'http://koreangrammars.modunsoft.com:3000/api/',
  // url: 'http://koreadictionary.modunsoft.com/',
  domain: 'https://dictionary5.wooridict.com/api/',
  url: 'https://dictionary5.wooridict.com/',
  API_URL: {
    LOGIN: 'accounts/users/login/',
    CHANGE_PASSWORD: 'accounts/users/change-password/',
    TOPIC: {
      GET_AUTO_COMPLETE: 'topic/auth-get-autocomplete/',
      GET: 'topic/auth-get/',
      DELETE: 'topic/delete/',
      CREATE: 'topic/create/',
      CREATE_MANY: 'topic/create-many/',
      UPDATE: 'topic/update/',
      SEARCH: 'topic/search',
    },
    SPECIAL: {
      GET_AUTO_COMPLETE: 'special/auth-get-autocomplete/',
      GET: 'special/auth-get/',
      DELETE: 'special/delete/',
      CREATE: 'special/create/',
      CREATE_MANY: 'special/create-many/',
      UPDATE: 'special/update/',
      SEARCH: 'special/search',
    },
    RULE: {
      GET_AUTO_COMPLETE: 'rule/auth-get-autocomplete/',
      GET: 'rule/auth-get/',
      DELETE: 'rule/delete/',
      CREATE: 'rule/create/',
      CREATE_MANY: 'rule/create-many/',
      UPDATE: 'rule/update/',
      SEARCH: 'rule/search',
    },
    GRAMMAR: {
      GET: 'grammar/auth-get/',
      DELETE: 'grammar/delete/',
      CREATE: 'grammar/create/',
      CREATE_MANY: 'grammar/create-many/',
      UPDATE: 'grammar/update/',
      SEARCH: 'grammar/search',
      SEARCH_GRAMMAR_TOPIK: 'grammar/search-topik',
      TOPIKI90: 'grammar/getTopikI',
      TOPIKII100: 'grammar/getTopikII',
      UPDATE_TOPIK_90_100: 'grammar/update-grammar-topik',
    },

    ADS: {
      GET: 'files/all',
      UPDATE: 'files/file/',
      UPLOAD: 'files/upload',
    },
  },

  parseJwt(token: String) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  },

  CKEDITOR: [
    {
      name: 'document',
      items: [
        'Source',
        '-',
        'Save',
        'NewPage',
        'ExportPdf',
        'Preview',
        'Print',
        '-',
        'Templates',
      ],
    },
    {
      name: 'clipboard',
      items: [
        'Cut',
        'Copy',
        'Paste',
        'PasteText',
        'PasteFromWord',
        '-',
        'Undo',
        'Redo',
      ],
    },
    {
      name: 'editing',
      items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
    },
    {
      name: 'forms',
      items: [
        'Form',
        'Checkbox',
        'Radio',
        'TextField',
        'Textarea',
        'Select',
        'Button',
        'ImageButton',
        'HiddenField',
      ],
    },
    '/',
    {
      name: 'basicstyles',
      items: [
        'Bold',
        'Italic',
        'Underline',
        'Strike',
        'Subscript',
        'Superscript',
        '-',
        'CopyFormatting',
        'RemoveFormat',
      ],
    },
    {
      name: 'paragraph',
      items: [
        'NumberedList',
        'BulletedList',
        '-',
        'Outdent',
        'Indent',
        '-',
        'Blockquote',
        'CreateDiv',
        '-',
        'JustifyLeft',
        'JustifyCenter',
        'JustifyRight',
        'JustifyBlock',
        '-',
        'BidiLtr',
        'BidiRtl',
        'Language',
      ],
    },
    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
    {
      name: 'insert',
      items: [
        'Image',
        'Flash',
        'Table',
        'HorizontalRule',
        'Smiley',
        'SpecialChar',
        'PageBreak',
        'Iframe',
      ],
    },
    '/',
    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
    { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
    { name: 'about', items: ['About'] },
  ],

  cipher: (salt: any) => {
    const textToChars = (text: any) =>
      text.split('').map((c: any) => c.charCodeAt(0));
    const byteHex = (n: any) => ('0' + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code: any) =>
      textToChars(salt).reduce((a: any, b: any) => a ^ b, code);

    return (text: any) =>
      text
        .split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
  },

  decipher: (salt: any) => {
    const textToChars = (text: any) =>
      text.split('').map((c: any) => c.charCodeAt(0));
    const applySaltToChar = (code: any) =>
      textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
    return (encoded: any) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex: any) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode: any) => String.fromCharCode(charCode))
        .join('');
  },
};

export default Config;
